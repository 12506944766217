import products from '../apis/products';
import instagram from '../apis/instagram';
import history from '../history';

import {
    SIGN_IN,
    SIGN_OUT,
    CREATE_PRODUCT,
    FETCH_PRODUCTS,
    FETCH_PRODUCT,
    DELETE_PRODUCT,
    EDIT_PRODUCT,
    CREATE_CHECKOUT_SESSION,
    FETCH_PRODUCT_CATEGORY,
    SIGN_IN_SS,
    SIGN_OUT_SS,
    FETCH_SESSION,
    FETCH_USER_SESSION,
    ADD_TO_BASKET,
    INCREASE_QUANTITY,
    DECREASE_QUANTITY,
    REMOVE_FROM_BASKET,
    FETCH_INSTA_IMAGES
} from './types';

export const signIn = (userId) => {
    return {
        type: SIGN_IN,
        payload: userId
    };
};

export const signOut = () => {
    return {
        type: SIGN_OUT
    };
};

export const signInSS = (user) => async dispatch => {
    const { username } = user;
    const { password } = user;
    const res = await products.get('/auth/authenticate', { auth: { username, password } })

    dispatch({ type: SIGN_IN_SS, payload: res.data })
};

export const signOutSS = () => async dispatch => {
    console.log('Something happened on Sign OUT')
    const res = await products.delete('/auth/remove-user-session');

    console.log(res)
    dispatch({ type: SIGN_OUT_SS })
};

export const createProduct = formValues => async (dispatch, getState) => {
    const { userId } = getState().auth;
    const formValuesAndUserId = { ...formValues, userId };
    console.log(formValuesAndUserId)

    const response = await products.post('/shop', formValuesAndUserId);

    dispatch({ type: CREATE_PRODUCT, payload: response.data })

    history.push('/shop');
};

export const fetchProducts = (num) => async dispatch => {
    if (num === undefined) {
        num = 0;
    }
    const response = await products.get(`/shop?amount=${num}`);

    dispatch({ type: FETCH_PRODUCTS, payload: response.data })
}

export const fetchRingProducts = () => async dispatch => {
    const response = await products.get('/category/rings');

    dispatch({ type: FETCH_PRODUCT_CATEGORY, payload: response.data })
}

export const fetchEarringProducts = () => async dispatch => {
    const response = await products.get('/category/earrings');

    dispatch({ type: FETCH_PRODUCT_CATEGORY, payload: response.data })
}

export const fetchNecklaceProducts = () => async dispatch => {
    const response = await products.get('/category/necklaces');

    dispatch({ type: FETCH_PRODUCT_CATEGORY, payload: response.data })
}

export const fetchProduct = (id) => async dispatch => {
    const response = await products.get(`/shop/${id}`);

    dispatch({ type: FETCH_PRODUCT, payload: response.data })

};

export const editProduct = (id, formValues) => async dispatch => {
    const response = await products.patch(`/shop/${id}`, formValues);

    dispatch({ type: EDIT_PRODUCT, payload: response.data })

    history.push('/shop');
}

export const deleteProduct = (id) => async dispatch => {
    await products.delete(`/shop/${id}`);

    dispatch({ type: DELETE_PRODUCT, payload: id });

    history.push('/shop')
};

export const checkOutSession = (productInfo, qty) => async dispatch => {
    console.log(productInfo);
    const response = await products.post(`/create-checkout-session`, productInfo);

    window.location.assign(response.data)
    // Do I need this?
    dispatch({ type: CREATE_CHECKOUT_SESSION, payload: response })
}

export const fetchSession = () => async dispatch => {
    const res = await products.get('/auth/read-cookie');


    dispatch({ type: FETCH_SESSION, payload: res.data.user })
}

// export const fetchUserSession = () => async dispatch => {
//     const res = await products.get('/auth/read-user-cookie')

//     console.log(res)
//     dispatch({ type: FETCH_USER_SESSION, payload: res.data.user })
// }

export const addToBasket = (product) => async dispatch => {
    // const res = await products.post(`/auth/add-to-basket`, product);
    // console.log(res.data)

    const quantity = 1;
    product = { ...product, quantity }
    dispatch({ type: ADD_TO_BASKET, payload: product })
}

export const removeFromBasket = (product) => async dispatch => {
    // const res = await products.post('/auth/remove-from-basket', product)
    // console.log(res.data)

    let { quantity } = product;
    quantity = 0;
    product = { ...product, quantity }

    dispatch({ type: REMOVE_FROM_BASKET, payload: product })
}

export const increaseQuantity = (product) => async dispatch => {
    product.quantity = product.quantity + 1;
    const updatedProduct = { ...product }

    dispatch({ type: INCREASE_QUANTITY, payload: updatedProduct })
}

export const decreaseQuantity = (product) => async dispatch => {
    product.quantity = product.quantity - 1;

    const updatedProduct = { ...product }

    dispatch({ type: DECREASE_QUANTITY, payload: updatedProduct })
}

export const fetchInstaPostImages = () => async dispatch => {
    const limit = 3;
    const accessToken = 'IGQVJXb3c2cE1SUDQ2X3BqVlJjbWFSTzN0MVpNUG5xTWFRUXhqWTVSekxSaVA5X0tZARk1RdFJZAYllETXgyN1NkbnBRUG1wZAjJybFFkdi1SeGdrdTZA6cXo4Rk1lVUZARaVJlUGpWQ21B'

    let response = await instagram.get(`?fields=media_url&limit=${limit}&access_token=${accessToken}`)

    dispatch({ type: FETCH_INSTA_IMAGES, payload: response.data.data })
}