import { FETCH_INSTA_IMAGES } from "../actions/types";

const instaGramReducer = (state = [], action) => {
    switch (action.type) {
        case FETCH_INSTA_IMAGES:

            return [...state, action.payload]

        default:
            return state

    }

}

export default instaGramReducer;