import _ from 'lodash';

import {
    CREATE_PRODUCT,
    FETCH_PRODUCTS,
    FETCH_PRODUCT,
    DELETE_PRODUCT,
    EDIT_PRODUCT,
    FETCH_PRODUCT_CATEGORY
} from '../actions/types'

const productReducer = (state = {}, action) => {
    switch (action.type) {
        case FETCH_PRODUCTS:
            // const productPie = action.payload.map((product) => {
            //     const {_id} = product 
            //     return {_id: product._id};
            // })
            // const productItems = Object.keys(action.payload).map((key) => {
            //     return action.payload[key];
            // })
            // productItems.map((product) => {
            //     return {...state, [product._id]: action.payload};
            // })
            return {...state, ..._.mapKeys(action.payload, '_id')};
        case FETCH_PRODUCT_CATEGORY:
            return {...state, ..._.mapKeys(action.payload, '_id')};
        case FETCH_PRODUCT:
            return {...state, [action.payload._id]: action.payload }

        case CREATE_PRODUCT:
            return {...state, [action.payload._id]: action.payload }

        case EDIT_PRODUCT:
            return {...state, [action.payload._id]: action.payload }

        case DELETE_PRODUCT:
            //USING LODASH - even though omit is being deprecated...
            return _.omit(state, action.payload)
            // IS IT POSSIBLE TO JUST USE THE DELETE FUNCTION? COULD BE BAD AS I@M DELETING THE STATE? 
            // const deleteEntry = delete action.payload
            // return {...state, deleteEntry}
            // SET THE ACTION PAYLOAD TO UNDEFINIED????
            // return {...state, [action.payload]: undefined}
        default: 
        return state;
    }
}

export default productReducer;