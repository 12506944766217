import React, { Suspense, lazy } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import history from '../history';
import { Helmet } from 'react-helmet';

import Home from './Home';
// import ProductList from './shop/ProductList';
// import ProductCategoryList from './shop/ProductCategoryList';
// import ProductCreate from './shop/ProductCreate';
// import ProductEdit from './shop/ProductEdit';
// import ProductDelete from './shop/ProductDelete';
// import ProductShow from './shop/ProductShow';
// import Confirmation from './checkout/OrderPreview';

// import GoogleAuth from './authentication/GoogleAuth';
import MyAuth from './authentication/MyAuth';

//Shop Components
const ProductList = lazy(() => import('./shop/ProductList'));
const ProductCategoryList = lazy(() => import('./shop/ProductCategoryList'));
const ProductCreate = lazy(() => import('./shop/ProductCreate'));
const ProductEdit = lazy(() => import('./shop/ProductEdit'));
const ProductDelete = lazy(() => import('./shop/ProductDelete'));
const ProductShow = lazy(() => import('./shop/ProductShow'));
// Basket Conponents 
const Confirmation = lazy(() => import('./checkout/OrderPreview'));
const Basket = lazy(() => import('./checkout/Basket'))
// About Components
const About = lazy(() => import('./about/About'))
const TermConditions = lazy(() => import('./about/TermsConditions'))
const Sustainability = lazy(() => import('./about/Sustainability'))
const Testimonials = lazy(() => import('./about/Testimonials'))
// Information Components
const BoringInfo = lazy(() => import('./information/BoringInfo'))
const RingSizeGuide = lazy(() => import('./information/RingSizeGuide'))


const App = () => {

    return (
        <Router history={history}>
            <Helmet titleTemplate="%s | Emma Gordon Jewellery">
            <meta name="robots" content="noindex"></meta>
            </Helmet>
            <Header />
            <Suspense fallback={'...Loading'}>
                <Switch>
                    <Route path='/' exact component={Home} />
                    <Route path='/shop' exact component={ProductList} />
                    <Route path='/shop/new' exact component={ProductCreate} />
                    <Route path='/shop/edit/:id' exact component={ProductEdit} />
                    <Route path='/shop/delete/:id' exact component={ProductDelete} />
                    <Route path='/shop/:id' exact component={ProductShow} />
                    <Route path='/confirmation/:result' exact component={Confirmation} />
                    <Route path='/category/:productCategory' exact component={ProductCategoryList} />
                    <Route path='/basket' exact component={Basket} />
                    <Route path='/signinemmagordon90' exact >
                        <MyAuth />
                    </Route>
                    <Route path='/about' exact component={About} />
                    <Route path='/terms-and-conditions' exact component={TermConditions} />
                    <Route path='/sustainability' exact component={Sustainability} />
                    <Route path='/testimonials' exact component={Testimonials} />
                    <Route path='/information/:page' exact component={BoringInfo} />
                    <Route path='/ring-size-guide' exact component={RingSizeGuide} />
                </Switch>
            </Suspense>
            <Footer />
        </Router >
    )
}
export default App;