import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchInstaPostImages } from '../../actions/'

const InstaFeed = () => {

    const dispatch = useDispatch();

    const stateToProps = useSelector((state) => {
        return state.instaGram
    })


    useEffect(() => {
        const abortController = new AbortController();

        dispatch(fetchInstaPostImages())

        return () => {
            // cancel pending fetch request on component unmount
            abortController.abort();
        };

    }, [dispatch])

    const renderInstaImages = () => {
        if (stateToProps[0] === undefined) {
            return null
        } else {
            return (stateToProps[0].map(image => {
                return (
                    <img key={image.id} src={image.media_url} alt="Instagram Photos" loading='lazy' />
                )
            })
            )
        }
    }


    return (
        <div className='insta-gram-section'>
            <h3>Follow our journey</h3>
            <h4>@EMMAGORDONLDN</h4>
            <div className='insta-images'>
                {renderInstaImages()}
            </div>
        </div>

    )
}

export default InstaFeed;