import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchProducts } from '../../actions';
import LoadingSpinner from '../sitewideComponents/LoadingSpinner';

const LatestProducts = () => {

    const latestProductSection = useRef(null);

    const [opacityArrow, setOpacity] = useState(0)

    const dispatch = useDispatch();

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });

        dispatch(fetchProducts(4));

        // if (stateToProps.products.length === 0) {
        //     dispatch(fetchProducts(4));
        // } else {
        //     console.log('Already fetched')
        // }


    }, [dispatch])

    const stateToProps = useSelector((state) => {
        return {
            products: Object.values(state.products),
            currentUserId: state.auth.userId,
            isSignedIn: state.myAuth.isSignedIn,
            isAdminUser: state.myAuth.user
        };
    });

    const renderList = () => {
        if (stateToProps.products.length === 0) {
            return <LoadingSpinner />
        }
        else {
            return stateToProps.products.slice(0, 4).map((product) => {
                return (
                    <div className="card" key={product._id}>
                        <Link to={`/shop/${product._id}`} >
                            <picture>
                                <source width={285} height={285} alt={`${product.title}`} type='image/avif' srcSet={`https://res.cloudinary.com/dc7k6oug8/image/upload/h_450,w_450/f_avif,q_auto/${product.primaryImage}`} />
                                <img width={285} height={285} alt={`${product.title}`} src={`https://res.cloudinary.com/dc7k6oug8/image/upload/h_450,w_450/f_auto,q_auto/${product.primaryImage}`} />

                            </picture>
                            <h3>{product.title}</h3>
                            <h5>£{product.price}</h5>
                        </Link>
                    </div>
                )
            })

        }
    }

    const prev = () => {
        latestProductSection.current.scrollLeft -= 285;
    }

    const next = () => {
        setOpacity(1)
        latestProductSection.current.scrollLeft += 285;
    }


    return (
        <>
            <div className="entry-product-section">
                <h2>Latest Designs</h2>
                <img onClick={prev} style={{ opacity: `${opacityArrow}` }} src='/images/arrow-left-turquoise-01.svg' alt='arrow' />
                <Link to="/shop">Shop All</Link>
                <img onClick={next} src='/images/arrow-right-turquoise-01.svg' alt='arrow' />
            </div>
            <div>
                <div ref={latestProductSection} className='card-section latest-products-section'>
                    {/* <span onClick={prev} className='arrows prev'><img src='/images/arrow-left-turquoise-01.svg' /></span> */}
                    {renderList()}
                    {/* <span onClick={next} className='arrows next'><img src='/images/arrow-right-turquoise-01.svg' /></span> */}
                </div>
                <div className='cta-link'>
                    <Link to={`/shop`}>Shop All</Link>
                </div>
            </div>
        </>
    )
}

export default LatestProducts;