import React from 'react';
import CookieConsent from 'react-cookie-consent';

const Cookies = () => {

    return (
        <CookieConsent
            disableStyles={true}
            contentClasses='content-consent'
            containerClasses='consent-banner'
            buttonClasses='accept-consent-btn'
            declineButtonClasses='decline-consent-btn'
            location='bottom'
            buttonText='Accept'
            enableDeclineButton
            onAccept={() => {
                alert('Yay');
                console.log('Add a snippet of code.')
            }}
            onDecline={() => {
                alert('nay!');
            }}
            flipButtons
        >
            This site uses cookies to provide necessary website functionality, improve your experience and analyze our traffic.
        </CookieConsent>
    )
}

export default Cookies