import React from 'react';
import { useState } from 'react';

const Banner = ({ content }) => {

    const [cross, hideCross] = useState(null)

    return (
        <div className={`top-banner ${cross}`}>
            <p>{content}</p>
            <span onClick={e => hideCross('hide-cross')} className='material-icons-outlined' >
                close
            </span>
        </div>
    )
}

export default Banner;