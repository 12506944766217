import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import LatestProducts from './shop/LatestProducts';
import InstaFeed from './social/InstaGramFeed';
import Cookies from './consent/CookieConsent';

const Home = (props) => {

    return (
        <>
            <Helmet>
                <title>Rings, Earrings and Necklaces</title>
                <link rel="canonical" href={`https://www.emmagordon.co.uk${props.location.pathname}`} />
            </Helmet>
            <div className="entry-content">
                <h1>Discover our rings</h1>
                <Link to="/shop" className='cta'>Shop Now</Link>
            </div>
            <LatestProducts />
            <div className="banner-section">
                <div className='banner-content'>
                    <h2>Our Story</h2>
                    <p>All our jewellery is handmade and crafted with love in London.</p>
                    <p>Look into our studios and meet the Designer.</p>
                    <span>
                        <Link to="/" className='cta'>Learn more</Link>
                    </span>
                </div>
                <div className='banner-image'>
                    <p></p>
                </div>
            </div>
            <InstaFeed />
            <Cookies />
        </>
    )
}

export default Home;