import {
    ADD_TO_BASKET, INCREASE_QUANTITY, DECREASE_QUANTITY, REMOVE_FROM_BASKET
} from '../actions/types'


const basketReducer = (state = [], action) => {
    switch (action.type) {
        case ADD_TO_BASKET:
            return [...state, { product: action.payload }]

        case REMOVE_FROM_BASKET:
            let updateState = state.filter((item) => {
                return item.product._id !== action.payload._id
            })
            return [...updateState]

        case INCREASE_QUANTITY:
            // console.log(action.payload.quantity)

            // state.map(item => {
            //     console.log(item.product.quantity)
            // })

            // return [...state, state.map(item => {
            //     item.product.quantity = action.payload.quantity
            // })]

            return [...state]

        case DECREASE_QUANTITY:
            return [...state]

        default:
            return state
    }
}

export default basketReducer;