import { combineReducers } from 'redux';
import authReducer from './authReducer';
import basketReducer from './basketReducers';
import myAuthReducer from './myAuthreducer';
import productReducer from './productReducers';
import instaGramReducer from './instaGramReducer';

export default combineReducers({
    auth: authReducer,
    products: productReducer,
    myAuth: myAuthReducer,
    basket: basketReducer,
    instaGram: instaGramReducer
});

