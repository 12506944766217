import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Field } from "react-final-form";
import { signInSS, fetchSession, signOutSS } from '../../actions';

const MyAuth = () => {

  const areTheySignedIn = useSelector((state) => state.myAuth.user);


  const dispatch = useDispatch();

  useEffect(() => {

    dispatch(fetchSession())

  }, [dispatch])

  const renderError = ({ error, touched }) => {
    if (touched && error) {
      return (
        <div>
          <div>{error}</div>
        </div>
      );
    }
  };

  const renderTextFields = ({ input, label, meta, }) => {
    const className = `field ${meta.error && meta.touched ? "error" : ""}`;
    return (
      <div className={className}>
        <label>{label}</label>
        <input {...input} autoComplete="off" />
        {renderError(meta)}
      </div>
    );
  };

  const onSubmit = (formValues) => {
    dispatch(signInSS(formValues))
  };

  const onSignOutClick = (e) => {
    e.preventDefault()
    dispatch(signOutSS());
  }

  const renderLogInLogOut = () => {
    if (!areTheySignedIn) {
      return (
        <Form
          onSubmit={onSubmit}
          validate={(formValues) => {
            const errors = {};

            if (!formValues.username) {
              errors.title = "You must enter a username";
            }

            if (!formValues.password) {
              errors.description = "You must enter a password";
            }

            return errors;
          }}
          render={({ handleSubmit }) => (
            <div className='entry'>
              <h3>Sign in...or out</h3>
              <form onSubmit={handleSubmit} >
                <Field
                  name="username"
                  component={renderTextFields}
                  label="Username"
                />
                <Field
                  name="password"
                  component={renderTextFields}
                  label="Enter Password"
                  type="password"
                />
                <button>Login</button>
              </form>
            </ div>
          )}
        />
      )
    } else {
      return (
        <form>
          <button onClick={onSignOutClick}>Log out</button>
        </form>
      )
    }
  }

  return (
    renderLogInLogOut()
  );
}

export default MyAuth;