export const SIGN_IN = 'SIGN_IN';
export const SIGN_OUT = 'SIGN_OUT';

export const SIGN_IN_SS = 'SIGN_IN_SS';
export const SIGN_OUT_SS = 'SIGN_OUT_SS';

export const CREATE_PRODUCT = 'CREATE_PRODUCT';
export const FETCH_PRODUCTS = 'FETCH_PRODUCTS';
export const FETCH_PRODUCT_CATEGORY = 'FETCH_PRODUCT_CATEGORY';
export const FETCH_PRODUCT = 'FETCH_PRODUCT';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const EDIT_PRODUCT = 'EDIT_PRODUCT';

export const CREATE_CHECKOUT_SESSION = 'CREATE_CHECKOUT_SESSION';
export const FETCH_SESSION = 'FETCH_SESSION';

export const ADD_TO_BASKET = 'ADD_TO_BASKET';
export const REMOVE_FROM_BASKET = 'REMOVE_FROM_BASKET';
export const INCREASE_QUANTITY = 'INCREASE_QUANTITY';
export const DECREASE_QUANTITY = 'DECREASE_QUANTITY';

export const FETCH_INSTA_IMAGES = 'FETCH_INSTA_IMAGES'