import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Banner from './sitewideComponents/Banner';
// import GoogleAuth from './authentication/GoogleAuth';

const Header = (props) => {

    const [isActive, setActive] = useState(false);
    const toggleClass = (e) => {
        e.preventDefault();
        setActive(!isActive);
    };



    // This will change it on every navagational click
    useEffect(() => {
        return props.history.listen((location) => {
            setActive(false);
        })
    }, [props.history])

    //THIS WILL CLOSE ONLY WHEN THE URL ACTUALLY CHANGES...
    //  useEffect(() => {
    //     console.log('You changed the pathname URL')
    //        setActive(false);
    //        setArrow('expand_more');

    //  },[props.history.location.pathname]) 

    const basket = useSelector((state) => {
        return { amount: state.basket.length }
    }
    )

    return (
        <>
            <Banner
                content='Handmade with love in the UK'
            />
            <nav>
                <div className='first-nav'>
                    <div className="burger">
                        <Link to="/">
                            <svg
                                className={`ham hamRotate ham7 ${isActive ? 'active' : null}`}
                                viewBox="0 0 100 100"
                                width="50"
                                // className={isActive ? 'active': null}
                                onClick={toggleClass}
                            >
                                <path className="line top" d="m 70,33 h -40 c 0,0 -6,1.368796 -6,8.5 0,7.131204 6,8.5013 6,8.5013 l 20,-0.0013" />
                                <path className="line middle" d="m 70,50 h -40" />
                                <path className="line bottom" d="m 69.575405,67.073826 h -40 c -5.592752,0 -6.873604,-9.348582 1.371031,-9.348582 8.244634,0 19.053564,21.797129 19.053564,12.274756 l 0,-40" />
                            </svg>
                        </Link>
                    </div>
                    <span className='left-icons'>
                        <Link className="" to="/">
                            <span className="social-icons hover-underline-animation"><img src="/images/IG-01-01.svg" alt="Instagram Logo"></img></span>
                        </Link>
                        <Link className="" to="/">
                            <span style={{ position: 'relative', left: '15px' }} className="social-icons hover-underline-animation"><img src="/images/IG-01-02.svg" alt="Email"></img></span>
                        </Link>
                    </span>
                    <div className="logo">
                        <Link to="/">
                            <picture>
                                <source srcSet="/images/Emma-Gordon-logo-mobile-11.svg" media="(max-width: 500px)" />
                                <img src="/images/image2vector-EG.svg" alt="Emma Gordon" />
                            </picture>
                        </Link>
                    </div>
                    <div className="right-nav">
                        <Link className="" to="/basket">
                            <span className="social-icons hover-underline-animation"><img src="/images/IG-01-03.svg" alt="Shopping Basket"></img></span>
                            <span
                                style={{ fontFamily: 'Brandon Grotesque Light', fontSize: '10px', position: 'relative', top: '-15px', left: '-5px', backgroundColor: 'rgb(251 243 240)', borderRadius: '15px', padding: '2px', opacity: '0.8' }}>
                                {basket.amount}
                            </span>
                        </Link>
                    </div>
                </div>
                <div className='second-nav'>
                    <span className="desktop-nav-links">
                        <div className="desktop-shop" style={{ display: "inline-flex" }}>
                            <Link to="/shop" >
                                <span className="hover-underline-animation">Shop</span>
                            </Link>
                            <span className="shop-desktop-hidden">
                                <Link to='/category/rings'>
                                    <div className='hover-nav'><span className='hover-underline-animation'>Rings</span></div>
                                </Link>
                                <Link to='/category/earrings'>
                                    <div className='hover-nav'><span className='hover-underline-animation'>Earrings</span></div>
                                </Link>
                                <Link to='/category/necklaces'>
                                    <div className='hover-nav'><span className='hover-underline-animation'>Necklaces</span></div>
                                </Link>
                            </span>

                        </div>
                        <Link to="/sustainability" >
                            <span className="hover-underline-animation">Sustainability</span>
                        </Link>
                        <Link to="/about" >
                            <span className="hover-underline-animation" >About</span>
                        </Link>
                        <Link to="/testimonials" >
                            <span className="hover-underline-animation">Testimonials</span>
                        </Link>
                    </span>
                </div>
            </nav>
            <div className={`expander-nav ${isActive ? 'expander-width' : null}`}>
                <Link to='/shop' >Shop
                    {/* <span style={{marginLeft: '0.5em',}} className={`material-icons-outlined arrowUp ${toggle ? 'arrowDown' : null}`}>
                        {arrow}
                    </span> */}
                </Link>
                <div className='nav-categories'>
                    <Link to='/category/rings'>Rings</Link>
                    <Link to='/category/earrings'>Earrings</Link>
                    <Link to='/category/necklaces'>Necklaces</Link>
                </div>
                {/* <div className={`navCollapsed ${toggle ? 'navOpen' : null}`}> 
                    <Link to="/category/earrings">Earrings</Link>
                    <Link to="/category/necklaces">Necklaces</Link>
                    <Link to="/category/rings">Rings</Link>
                </div> */}
                <Link to="/sustainability">Sustainability</Link>
                <Link to="/about">About</Link>
                <Link to="/" >Contact</Link>
                <Link to="/testimonials" >Testimonials</Link>
                {/* <GoogleAuth /> */}
            </div>
        </>
    )
}

export default withRouter(Header);