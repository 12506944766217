import { FETCH_SESSION, SIGN_IN_SS, SIGN_OUT_SS } from "../actions/types";

const INITIAL_STATE = {
    isSignedIn: null, 
    user: null
};

const myAuthReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SIGN_IN_SS:
            return {...state, isSignedIn: true, user: action.payload }
        case SIGN_OUT_SS:
            return {...state, isSignedIn: false, user: null}
        case FETCH_SESSION:
            return {...state, user: action.payload }
        default: 
            return state;
    }
};

export default myAuthReducer;