import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer>
            <div className='footer-section'>
                <Link to="/">
                    <span className="social-icons hover-underline-animation"><img src="/images/IG-01-01.svg" alt="Instagram Logo"></img></span>
                </Link>
                <Link to="/">
                    <span className="social-icons hover-underline-animation"><img src="/images/IG-01-02.svg" alt="Email"></img></span>

                </Link>
            </div>
            <div className='footer-section'>
                <Link to="/sustainability">
                    <span className='hover-underline-animation'>Sustainability</span>
                </Link>
                <Link to="/information/care-and-cleaning">
                    <span className='hover-underline-animation' >Care &#38; Cleaning</span>
                </Link>
                <Link to="/ring-size-guide">
                    <span className='hover-underline-animation' >Ring Size Guide</span>
                </Link>
                <Link to="/terms-and-conditions">
                    <span className='hover-underline-animation' >Terms &#38; Conditions</span>
                </Link>
                <Link to="/information/delivery-information">
                    <span className='hover-underline-animation' >Delivery Information</span>
                </Link>
                <Link to="/information/privacy-policy">
                    <span className='hover-underline-animation' >Privacy Policy</span>
                </Link>
            </div>
            <div className='footer-section'>
                <p>&#169; Copyright Emma Gordon London 2022. All rights reserved</p>
            </div>

        </footer>
    )
}

export default Footer;